import React, { useEffect, useState } from "react";
import "./style.scss";
import { Image, Typography } from "antd";
import logo from "../../Assets/Images/logo-2.svg";
import Title from "antd/es/typography/Title";
import { Link as ScrollLink /*, animateScroll as scroll*/ } from "react-scroll";
import JoinWaitlistModal from "../JoinWaitlist";
import { Link } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../Config/firebase";

const DeskBar = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    variant: "seeker",
    state: false,
  });

  const handleOk = () => setIsModalOpen({ variant: "seeker", state: false });
  const handleCancel = () =>
    setIsModalOpen({ variant: "seeker", state: false });

  useEffect(() => {
    window.addEventListener("scroll", isSticky);

    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []); // Empty dependency array ensures that the effect runs once (like componentDidMount)

  const isSticky = (e) => {
    const header = document.querySelector(".nav-container-desktop");
    const scrollTop = window.scrollY;
    scrollTop >= 100
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  const handleClicker = (type) => {
    setIsModalOpen({ variant: type, state: true });
    logEvent(analytics, `${type}_onboarding_started`);
  };

  return (
    <div className="nav-container-desktop">
      <div className="logo_wrapper">
        <Image src={logo} width="200px" preview={false} />
      </div>
      <div className="nav-link-wrapper">
        <Link
          to="/#home"
          activeClass="active"
          spy={true}
          smooth={true}
          id="home-link"
        >
          <Title
            level={4}
            className="nav-link-item"
            style={{ marginTop: "0px" }}
          >
            Home
          </Title>
        </Link>
        <Link to="/#what-we-do" id="what-we-do-link">
          <Title
            level={4}
            className="nav-link-item"
            style={{ marginTop: "0px" }}
          >
            What We Do
          </Title>
        </Link>
        <Link to="/#faq" id="faq-link">
          <Title
            level={4}
            className="nav-link-item"
            style={{ marginTop: "0px" }}
          >
            FAQs
          </Title>
        </Link>
        <Link to="/about" id="why-we-built-link">
          <Title
            level={4}
            className="nav-link-item"
            style={{ marginTop: "0px" }}
          >
            About
          </Title>
        </Link>

        <Link to="/newsletter" id="news-letter">
          <Title
            level={4}
            className="nav-link-item"
            style={{ marginTop: "0px" }}
          >
            Newsletter
          </Title>
        </Link>

        {/* Add ScrollLink components for other sections with their respective IDs */}
      </div>

      <div className="nav-actions">
        <a
          href="https://candidate.stealthjobs.co/"
          target="_blank"
          rel="noreferrer"
        >
          <button size="large" className="contained_button">
            I'm a Leader
          </button>
        </a>{" "}
        <a
          href="https://employer.stealthjobs.co/"
          target="_blank"
          rel="noreferrer"
        >
          <button
            type="primary"
            className="outlined_button"
            size="large"
            // onClick={() => handleClicker("employer")}
          >
            I'm Recruiting
          </button>
        </a>
      </div>
      <JoinWaitlistModal
        isModalOpen={isModalOpen.state}
        handleOk={handleOk}
        handleCancel={handleCancel}
        defaultValue={isModalOpen.variant}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default DeskBar;
