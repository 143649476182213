import { Button, Form, Modal, Radio } from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";
import SeekerApplication from "../Forms/SeekerApplication";
import EmployerApplication from "../Forms/EmployerApplication";
import Title from "antd/es/typography/Title";
import Success from "./Success";
import {
  getAllExperienceLevels,
  getAllJobFunctions,
  joinWaitlist,
  saveForm,
} from "../../API/waitlist";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../Config/firebase";

const JoinWaitlistModal = ({
  isModalOpen,
  setIsModalOpen,
  handleCancel,
  defaultValue,
}) => {
  const [selectedTab, setSelectedTab] = useState(defaultValue);
  const [isSuccess, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleSelection = (e) => setSelectedTab(e.target.value);
  useEffect(() => setSelectedTab(defaultValue), [defaultValue]);
  const [loadSkeleton, setLoadSkeleton] = useState(false);
  const [jobFunctionName, setJobFunctionName] = useState("");
  const [jobFunctionValue, setFunctionValue] = useState("");
  const [experienceName, setExperienceName] = useState("");
  const [experienceLevelValue, setExperienceLevel] = useState("");
  const onFinish = (values) => {
    // console.log("values", values, jobFunctionValue);
    joinWaitlist({
      formData: { ...values, experienceLevel: experienceLevelValue },
      type: "seeker",
      setSuccess,
      setLoading,
    });
    saveForm({
      formData: {
        ...values,
        experienceLevel: experienceLevelValue,
        jobFunction: jobFunctionValue,
      },
      type: "seeker",
      setSuccess,
      setLoading,
    });
    logEvent(analytics, `seeker_onboarding_completed`);
  };
  const jobFunctionNameHandler = (data) => {
    const temp = [];
    data.map(({ id, name }) => temp.push({ value: id, label: name }));
    setJobFunctionName(temp);
  };
  const experienceNameHandler = (data) => {
    const temp = [];
    data.map(({ id, name }) => temp.push({ value: id, label: name }));
    setExperienceName(temp);
  };
  const loadData = async () => {
    setLoadSkeleton(true);
    await getAllJobFunctions({
      jobFunctionNameHandler,
    });
    await getAllExperienceLevels({
      experienceNameHandler,
    });
    setLoadSkeleton(false);
  };
  useEffect(() => {
    loadData();
  }, []);
  const seekersProps = {
    loadSkeleton,
    onFinish,
    jobFunctionName,
    experienceName,
    setExperienceLevel,
    isLoading,
    setFunctionValue,
  };
  return (
    <Modal
      open={isModalOpen}
      // onOk={handleOk}
      onCancel={handleCancel}
      centered
      footer={null}
    >
      {isSuccess ? (
        <Success setSuccess={setSuccess} setIsModalOpen={setIsModalOpen} />
      ) : (
        <>
          {/* <div className="flex-center radio-group-container">
            <Radio.Group
              defaultValue={defaultValue}
              value={selectedTab}
              onChange={handleSelection}
              size="large"
            >
              <Radio.Button value="seeker">I'm a Leader</Radio.Button>
              <Radio.Button value="employer">I'm Recruiting</Radio.Button>
            </Radio.Group>
          </div> */}
          <div className="dialog-form-container">
            <Title level={4} className="application-heading">
              Please provide the following to join our waitlist.
            </Title>
            {selectedTab === "seeker" ? (
              <SeekerApplication setSuccess={setSuccess} {...seekersProps} />
            ) : (
              <EmployerApplication setSuccess={setSuccess} />
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default JoinWaitlistModal;
